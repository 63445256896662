<template>
  <div  class="body">
    <div class="authentication">
    </div>
    <div class="scan-img">
      <van-image
          class="scan-img"
          :src="bgUrl"
      ></van-image>
    </div>
    <div style="margin-top:10px">
      <van-form>
<!--        <van-field
            v-model="form.type"
            label="证照类型:"
            placeholder="证照类型"
            disabled
        />-->
        <van-field
            v-model="form.nameText"
            label="姓名:"
            placeholder="姓名"
            disabled
            :rules="[{ required: true, message: '请填写姓名' }]"
        />
        <van-field
            v-model="form.numberText"
            label="身份证号码:"
            placeholder="身份证号码"
            disabled
            :rules="[{ pattern, message: '请输入正确身份证号码' }]"
        />
        <div style="height:50px;width:100%"></div>
         <van-button round type="primary" plain  size="large" @click="recertification">重新认证</van-button>
         <div style="padding:10px;width:100%;height:1px"></div>
         <van-button round type="primary"  size="large" @click="jumpAuthentication">返回首页</van-button>
      </van-form>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  components: {

  },
  props: {},
  data() {
    return {
      nameText:'',
      form:{
        type:'身份证',
        nameText:'',
        numberText:'',
      },
    }
  },
  computed: {
    bgUrl(){
      return require("@/assets/img/auth_err.png")
    },
    postureUrl(){
      return require("@/assets/img/posture.png")
    }
  },
  watch: {
  },
  created() {
    this.form.nameText=this.$route.query.nameText
    let nameText1=this.$route.query.numberText.toString()
    let numberText = nameText1.slice(0,2)+'***********'+nameText1.slice(nameText1.length-2,nameText1.length)
    this.form.numberText=numberText
  },
  mounted() {

  },
  onLoad(options) {

  },
  methods: {
    recertification(){
      this.$router.push({path: '/authentication'})
    },
    jumpAuthentication(){
      wx.miniProgram.navigateBack({})
     /* document.addEventListener('WeixinJSBridgeReady', function() {
        WeixinJSBridge.call('closeWindow');
      }, false);
      WeixinJSBridge.call('closeWindow');*/
    }
  },
}
</script>
<style lang='scss' scoped>
.body{
  background-color: white;
}
.connect{
  text-align: center;
  margin-top:30px;
  color: blue;
}
.authentication{
  text-align: center;
  padding-top:120px;
  font-size: 25px;
  font-weight: bold;
}
.scan-img{
  opacity: 0.9;
  width: 10rem;
  height:10rem;
  margin: auto;
}
.posture{
  opacity: 0.9;
  width: 20rem;
  height:10rem;
  margin: auto;
}
.button{
  margin-top:30px;
}
</style>
